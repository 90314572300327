<template>
  <div class="setting-item s-mask">
    <esmp-switch
      v-model="localSetting.value"
      shape="circular"
      :label="localSetting.name"
    />
    <div
      v-if="localSetting.value && localSetting.childs"
      class="setting-item__child-settings"
    >
      <div
        class="setting-item__child-setting"
        v-for="child in localSetting.childs"
        :key="child.id"
      >
        <esmp-input
          v-if="child.type === 'input'"
          :label="child.name"
          v-model="child.value"
          :readonly="isReadOnly(child)"
        />
      </div>
      <div
        class="setting-item__child-setting setting-item__child-setting--small-offset"
        v-for="v in variants"
        :key="v.value"
      >
        <div>
          <esmp-radio
            v-model="variant"
            :value="v.value"
            :label="v.label"
            name="variant"
          />
        </div>
        <template v-if="v.value === 'date' && variant === 'date'">
          <esmp-radio-group
            v-model="availableDatesValue"
            :items="availableDates"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
const getAvailableDatesValue = (childs) => {
  const value = childs.find((el) => el.techName === 'datesToShowType')?.value;
  return value ? Number(value) : null;
};

export default {
  name: 'SMask',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      /* eslint-disable */
      variant: 'single',
      variants: [
        {
          label: 'Простая маска',
          value: 'single',
          mask: '',
          description: 'Пример: ',
          readonly: false,
        },
        {
          label: 'Только цифры',
          value: 'numbers',
          mask: '^\\d+$',
          description: "Пример: '317534' (без пробелов)",
          readonly: true,
        },
        {
          label: 'Только латинские буквы',
          value: 'latinsletters',
          mask: '^[a-zA-Z ]*$',
          description: "Пример: 'Some example words'",
          readonly: true,
        },
        {
          label: 'Только буквы кириллицы',
          value: 'cyrilicletters',
          mask: '^[а-яА-ЯЁё ]*$',
          description: "Пример: 'Несколько слов для примера'",
          readonly: true,
        },
        {
          label: 'Дата',
          value: 'date',
          mask:
            '^\\s*(3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2})+$',
          description: "Пример: '17.01.2018' (без пробелов)",
          readonly: true,
        },
        {
          label: 'Время',
          value: 'time',
          mask: '^([01]\\d|2[0-3]):?([0-5]\\d)$',
          description: "Примеры: '01:23', '23:45' (без пробелов)",
          readonly: true,
        },
        {
          label: 'Email',
          value: 'email',
          mask:
            '^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$',
          description: "Пример: 'some@example.ru (без пробелов)'",
          readonly: true,
        },
        {
          label: 'Телефон',
          value: 'phone',
          mask:
            '^(\\+7|7|8)?[\\s\\-]?\\(?[3489][0-9]{2}\\)?[\\s\\-]?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}$',
          description:
            "Примеры: '+79855310868', '8 985 531 08 68', '+7(926)777-77-77', '84456464641'",
          readonly: true,
        },
        {
          label: 'Мобильный с 8',
          value: 'phoneExtra',
          mask: '^8[489][0-9]{9}$',
          description: "Пример: '84456464641'",
          readonly: true,
        },
        {
          label: 'Ip-адрес',
          value: 'ipaddress',
          mask:
            '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
          description: 'Пример: 255.255.255.255',
          readonly: true,
        },
        {
          label: 'Порты',
          value: 'ports',
          mask:
            '^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])([-,]([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])){0,}?$',
          description: "Пример: '255-1-255-33', '255,1,255,33'",
          readonly: true,
        },
        {
          label: 'ИНН',
          value: 'inn',
          mask: 'function:inn', // TODO: как временная мера, пока маски не переедут на бек
          description: "10-12 цифр. Пример: 1234567890",
          readonly: true,
        },
      ],
      /* eslint-enable */
      availableDatesValue: getAvailableDatesValue(this.setting.childs),
      availableDates: [
        {
          label: 'Без ограничений',
          value: 0,
        },
        {
          label: 'Только прошедшие',
          value: 1,
        },
        {
          label: 'Только будущие',
          value: 2,
        },
      ],
    };
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    activeVariant() {
      return this.variants.find((i) => i.value === this.variant);
    },
  },
  methods: {
    isReadOnly(el) {
      return el.techName === 'mask' && this.activeVariant?.readonly;
    },
  },
  created() {
    const mask = this.setting.childs.find((el) => el.techName === 'mask').value;
    this.variant = this.variants.find((i) => i.mask === mask).value;
  },
  watch: {
    activeVariant(val) {
      this.localSetting.childs = this.localSetting.childs.map((el) => {
        if (el.techName === 'mask') Object.assign(el, { value: val.mask });
        if (el.techName === 'maskDescription') Object.assign(el, { value: val.description });
        return el;
      });
    },
    availableDatesValue(val) {
      this.localSetting.childs = this.localSetting.childs.map((el) => {
        if (el.techName === 'datesToShowType') Object.assign(el, { value: Number(val) });
        return el;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/blocks/control-settings.scss';
</style>
